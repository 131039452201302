import { INITIAL_GLOBAL_DATA_STATE } from 'constants/globalData'

// ****** BE CAREFUL IF YOU'RE CHANGING THIS, REMEMBER TO CHANGE IT IN THE getGlobalData FUNCTION BELOW TOO ******
export const KEY_GLOBAL_DATA = 'GLOBAL_DATA'
export const FLAGS_DATA = 'FLAGS_DATA'
export const USABILLA_LIVE_DATA = 'USABILLA_LIVE_DATA'

function storageAvailable(storage) {
  try {
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}

export function localStorageAvailable() {
  return storageAvailable(window.localStorage)
}

export function setLocalData(key, value) {
  if (localStorageAvailable()) {
    localStorage.setItem(key, value)
  }
}

export function removeLocalData(key) {
  if (localStorageAvailable()) {
    localStorage.removeItem(key)
  }
}

export function getLocalData(key) {
  if (localStorageAvailable()) {
    return localStorage.getItem(key)
  }
  return undefined
}

export function clearGlobalData() {
  if (localStorageAvailable()) {
    localStorage.removeItem(KEY_GLOBAL_DATA)
  }
}

export function clearFlagsData() {
  if (localStorageAvailable()) {
    localStorage.removeItem(FLAGS_DATA)
  }
}

export function clearUsabillaLiveData() {
  if (localStorageAvailable()) {
    localStorage.removeItem(USABILLA_LIVE_DATA)
  }
}

export function getGlobalData() {
  if (!localStorageAvailable()) {
    return {}
  }

  try {
    // don't ask why we have to have the key here, if we use the const above, we always get null 🤷‍
    // by using the string, we actually get the data
    const lsData = localStorage.getItem('GLOBAL_DATA')
    return JSON.parse(lsData || '{}')
  } catch (ex) {
    return {}
  }
}

export function persistGlobalData(state) {
  const globalData = { ...state.globalData }
  if (!globalData) {
    return
  }
  // TBF this should always exist given our browser support, but best to check
  if (localStorageAvailable()) {
    const stringified = JSON.stringify({
      ...globalData,
      // strip out some sensitive data
      impersonatingUser: INITIAL_GLOBAL_DATA_STATE.impersonatingUser,
      user: {
        ...globalData.user,
        email: INITIAL_GLOBAL_DATA_STATE.user.email
      }
    })

    localStorage.setItem(KEY_GLOBAL_DATA, stringified)
  }
}

export function getJsonFromLocalStorage(key, fallbackValue) {
  const storedValue = getLocalData(key)
  return storedValue ? JSON.parse(storedValue) : fallbackValue
}

export function setJsonInLocalStorage(key, data) {
  setLocalData(key, JSON.stringify(data))
}
